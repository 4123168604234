import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CloseButton,
  ModalContent,
  Title,
  Description,
  ModalPopup,
  modalStyles
} from './styled';
import Icon from '../Icon';
import CloseIcon from '../../public/static/images/item/close.svg';
import { Modal } from '../ui/Modal';
import { Button } from '../ui/Button';
import { PROFILE_ROUTES } from '../../utils/helpers/constants';
import useProfileBusinessTabsState from '../../hooks/useProfileBusinessTabsState';

export interface WelcomeBannerProps {
  id: string;
  title: string;
  description: string;
  isOpen: boolean;
  hide: () => void;
}

export const WelcomeBanner: React.FC<WelcomeBannerProps> = ({
  id,
  title,
  description,
  isOpen,
  hide
}) => {
  const { t } = useTranslation();

  const { optionHandleClick } = useProfileBusinessTabsState();

  const handleOpenProfilePackages = () => {
    hide();
    optionHandleClick('business');
    setTimeout(() => {
      window.location = PROFILE_ROUTES.PACKAGES;
    }, 500);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={hide}
      modalId="replenishment-notification-modal"
      cypress="replenishment-notification-modal"
      modalStyles={modalStyles}
      title=""
      closeButtonAlign="right"
      setMediaStyles={false}
    >
      <ModalPopup>
        <CloseButton
          onClick={hide}
          data-stat="replenishment-notification-close"
        >
          <i onClick={hide} onKeyPress={hide} role="button" tabIndex={-1}>
            <Icon
              indent={false}
              IconComponent={CloseIcon}
              width={15}
              height={15}
              color="white"
            />
          </i>
        </CloseButton>
        <ModalContent>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Button
            onClick={handleOpenProfilePackages}
            cypress="replenishment-notification-button"
            stat="replenishment-notification-button"
          >
            {t('common.details')}
          </Button>
        </ModalContent>
      </ModalPopup>
    </Modal>
  );
};
