import styled, { css } from 'styled-components';
import { Typography } from '../ui/Typography';

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const modalStyles = css`
  width: calc(100% - 30px);
  max-width: 440px;
  border-radius: 15px;
  height: max-content;
  align-self: center;
`;

export const ModalPopup = styled.div`
  padding: 165px 0 0;
  align-items: baseline;
  background-image: url('/static/images/welcome-bonus.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;

  @media (min-width: 320px) and (max-width: 374px) {
    background-position: top center;
    background-size: 345px 165px;
  }

  @media (min-width: 475px) {
    padding-top: 210px;
  }
`;

export const ModalContent = styled.div`
  background-color: ${({ theme: { colors } }) => colors.background.primary};
  border-radius: 15px;
  border: 1px solid #f1f3f7;
  padding: 25px 15px;
  text-align: center;
`;

export const Title = styled(Typography).attrs({ tag: 'title_2' })`
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
`;

export const Description = styled(Typography).attrs({ tag: 'body_2' })`
  display: block;
  margin-bottom: 20px;
`;
