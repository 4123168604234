class TabsStorage {
  constructor() {
    this.activeTab = '_at';
  }

  setActiveTab(tab) {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem(this.activeTab, tab);
    }
  }

  getActiveTab() {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.getItem(this.activeTab);
    }
  }
}

export const ProfileBusinessTabsStorage = new TabsStorage();
