import { gql } from '@apollo/client';

export const readNotificationQuery = gql`
  mutation readNotification($id: ID!) {
    readNotification(input: { id: $id }) {
      errors {
        code
        message
        path
      }
    }
  }
`;
