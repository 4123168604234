import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileBusinessTabsStorage } from '../utils/helpers/profileBusinessTabsStorage';

export default () => {
  const { t } = useTranslation();
  const textsArray = [
    t('pages.profile.packages'),
    t('pages.profile.balance'),
    t('pages.profile.invoices')
  ];

  const tabValuesArray = [
    {
      value: 'packages',
      stat: 'profile-business-packages'
    },
    {
      value: 'business',
      stat: 'profile-business-balance'
    },
    {
      value: 'invoices',
      stat: 'profile-business-invoices'
    }
  ];
  const initialActiveTab =
    ProfileBusinessTabsStorage.getActiveTab() || tabValuesArray[0].value;
  const [currentTabValue, setCurrentTabValue] = useState(initialActiveTab);

  const optionHandleClick = value => {
    setCurrentTabValue(value);
    ProfileBusinessTabsStorage.setActiveTab(value);
  };

  return {
    currentTabValue,
    optionHandleClick,
    textsArray,
    tabValuesArray
  };
};
