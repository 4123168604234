import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { ModalBackdrop, ModalWrapper } from './styled';
import { ModalProps } from './types';
import { ModalHeader } from './ModalHeader';
import { ModalFooter } from './ModalFooter';

export const Modal = ({
  isOpen,
  onClose,
  children,
  modalId,
  cypress,
  title,
  resetText,
  onReset,
  resetIsDisabled,
  confirmText,
  onConfirm,
  showHeaderBorder = true,
  footerStyles,
  modalStyles,
  closeButtonAlign = 'left',
  setMediaStyles = true
}: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = originalStyle;
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalBackdrop data-cy={cypress} onClick={onClose} id={modalId}>
      <ModalWrapper
        onClick={e => e.stopPropagation()}
        styles={modalStyles}
        $mediaStyles={setMediaStyles}
      >
        {title && (
          <ModalHeader
            cypress={`${cypress}--title`}
            title={title}
            onClose={onClose}
            showHeaderBorder={showHeaderBorder}
            closeButtonAlign={closeButtonAlign}
          />
        )}
        {children}
        {resetText && confirmText && onConfirm && onReset && (
          <ModalFooter
            resetText={resetText}
            onReset={onReset}
            confirmText={confirmText}
            onConfirm={onConfirm}
            resetIsDisabled={!!resetIsDisabled}
            resetCypress={`${cypress}--reset-btn`}
            confirmCypress={`${cypress}--submit-button`}
            styles={footerStyles}
          />
        )}
      </ModalWrapper>
    </ModalBackdrop>,
    document.body
  );
};
